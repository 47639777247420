// 突出普遍价值（SOUV）声明材料
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                prop: 'ZLMC',
                label: '资料名称',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'LX',
                label: '声明材料类型',
                minWidth: 105,
                // transList: {
                //     0: '突出普遍价值声明',
                //     1: '回顾性突出普遍价值声明'
                // },
            }, {
                prop: 'CNSMCLDSJ',
                label: '采纳时间',
                minWidth: 105,
                sortable: 'custom'
            }, {
                prop: 'LRBZ',
                label: '列入标准',
            }, {
                prop: 'ZSZW',
                label: '简要综述中文',
                minWidth: 105,
                showoverflowtooltip: true
            }, {
                prop: 'LJ',
                label: '附件',
                showFileBtn: true,
            }],
        },
    },
    snSearch: {
        selectData: [{
            label: '声明材料类型',
            clearable: true,
            placeholder: "请选择声明材料类型",
            list: [{
                ID: '1',
                NAME: '回顾性突出普遍价值声明'
            }, {
                ID: '0',
                NAME: '突出普遍价值声明'
            }],
            optionLabel: 'NAME',
            optionValue: 'ID',
            value: 'LX',
            operateType: 'search',
            isShow: true
        }, {
            label: '列入标准',
            multiple: true,
            clearable: true,
            placeholder: "请选择列入标准",
            list: [
                { value: '(i)' },
                { value: '(ii)' },
                { value: '(iii)' },
                { value: '(iv)' },
                { value: '(v)' },
                { value: '(vi)' },
                { value: '(vii)' },
                { value: '(viii)' },
                { value: '(ix)' },
                { value: '(x)' }
            ],
            value: 'LRBZ',
            optionLabel: 'value',
            optionValue: 'value',
            operateType: 'search',
            isShow: true
        }]
    }
};
export default selfData;